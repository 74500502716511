/* eslint-disable */

import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';

import 'swiper/css/bundle'

const breakpoint = window.matchMedia('(min-width:992px)')

const breakpointChecker = function () {
  

  if ( breakpoint.matches === true ) {
    if ( mySwiper !== undefined ) mySwiper.destroy( true, true );

    return;
  } else if ( breakpoint.matches === false ) {
    return enableSwiper();

  }
};

const enableSwiper = function () {
  const swiperContainer = document.querySelector(".featured-services-block__slider");
  let mySwiper;
  
  if (swiperContainer !== null) {
    /*
    * dublicate slides to not have an issue
    * https://www.notion.so/buzzword/Align-card-content-to-top-not-bottom-960476c614374af0b5a651a4bdb14a8a
    */
    const swiperWrapper = swiperContainer?.querySelector(".swiper-wrapper");
    const swiperSlides = document.querySelectorAll(".featured-services-block__slider .swiper-slide");
    swiperSlides?.forEach((slide,_) => {
      const clonedSlide = slide.cloneNode(true);
      swiperWrapper.appendChild(clonedSlide);
    });
    
    const params = {
      modules: [Navigation, Pagination, Autoplay, EffectFade],
      slidesPerView: 2.3,
      slidesToScroll: 1,
      initialSlide: 2,
      spaceBetween: 15,
      loop: true,
      speed: 2000,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: "bullets",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3
      },
      navigation: false,
      breakpoints: {
        320: {
          slidesPerView: "auto",
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };

    mySwiper = new Swiper(swiperContainer, params)
  }
}

enableSwiper()

// breakpoint.addListener(breakpointChecker);
// breakpointChecker();
